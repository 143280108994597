<template>
  <BaseButton
    :type="props.type"
    :class="props.class"
    class="bg-white hover:bg-gray-100 hover:text-gray-900"
    :disabled="props.disabled"
  >
    <slot />
  </BaseButton>
</template>
<script setup>
import BaseButton from './BaseButtonFlowBite.vue';

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>
